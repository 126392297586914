
import Vue, { VueConstructor } from 'vue'
import FullCalendar, {CalendarOptions, EventApi, DateSelectArg, EventDropArg } from "@fullcalendar/vue"
import resourceTimelinePlugin from "@fullcalendar/resource-timeline"
import type { EventInput, EventContentArg, VerboseFormattingArg, EventClickArg } from "@fullcalendar/common"
import interactionPlugin, { EventResizeDoneArg } from "@fullcalendar/interaction"
import frLocale from "@fullcalendar/core/locales/fr"
import enLocale from "@fullcalendar/core/locales/en-gb"
import axios, { AxiosError, AxiosResponse } from "axios"
import { ResourceInput } from "@fullcalendar/resource-common"
import dateFormatMixin from "@/mixins/DateFormatMixin.vue"
import { EventBus } from "@/plugins/eventBus"
import DateTimePicker from "@/components/DateTimePicker.vue"

const EMOJI = 12
const emojiPatternRegex =
/(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;

interface APIAvailability {
  id: number
  status: string
  date_action: string
  date_begin: string
  date_end: string
}

interface EventCalendar {
  event: EventApi,
  oldEvent: {
    startStr: string,
    endStr: string
  },
  revert: () => void,
  setExtendedProp: (name: string, value: string) => void
}

interface AvailabilityBody {
  status?: string,
  date_begin? : string,
  date_end? : string,
  comment?: string
}

export default (Vue as VueConstructor<Vue & InstanceType<typeof dateFormatMixin>>).extend({
  name: 'WorkerChronology',
  components: {
    FullCalendar, // make the <FullCalendar> tag available
    DateTimePicker
},
  mixins: [dateFormatMixin],
  props: {
    resources: {
      type: Array,
      required: true,
    },
    resourceOrder: {
      type: String
    },
    selectedItems: {
      type: Object
    }
  },
  data: function () {
    return {
      startDatetime: {dateObject: new Date(), dateString: "", timeString: ""},
      endDatetime: {dateObject: new Date(), dateString: "", timeString: ""},
      calendarOptions: {
        locales: [frLocale, enLocale],
        locale: this.$vuetify.lang.current,
        plugins: [resourceTimelinePlugin, interactionPlugin],
        schedulerLicenseKey: process.env.VUE_APP_FULLCALENDAR_LICENSE_KEY,
        headerToolbar: {
          left: "resourceTimelineCustomWeek,resourceTimelineCustomMonth",
          center: "title",
        },
        footerToolbar: {
          left: "resourceTimelineCustomWeek,resourceTimelineCustomMonth",
          end: "today prev,next"
        },
        initialView: "resourceTimelineCustomMonth",
        // Custom views
        views: {
          resourceTimelineCustomMonth: {
            eventMinWidth: 1,
            type: "resourceTimeline",
            duration: { months: 1 },
            buttonText: this.$vuetify.lang.t("$vuetify.month"),
            // The frequency for displaying time slots. https://fullcalendar.io/docs/slotDuration
            slotDuration: { hours: 24 },
            // The frequency that the time slots should be labelled with text. https://fullcalendar.io/docs/slotLabelInterval
            slotLabelInterval: { hours: 24 },
            // https://fullcalendar.io/docs/eventTimeFormat
            slotLabelFormat: { day: "numeric", weekday: "narrow"}
          },
          resourceTimelineCustomWeek: {
            eventMinWidth: 1,
            type: 'resourceTimeline',
            duration: { weeks: 1 },
            // configuring day range from 06:00:00 to next day 06:00:00
            nextDayThreshold: { hours: 6 },
            slotMinTime: { hours: 6 },
            slotMaxTime: { hours: 30 },
            buttonText: this.$vuetify.lang.t("$vuetify.week"),
            slotDuration: { hours: 8 },
            slotLabelInterval: { hours: 8 }
          }
        },
        // Determines how far forward the scroll pane is initially scrolled. https://fullcalendar.io/docs/scrollTime
        scrollTime: "00:00:00",
        // Sets the height of the view area of the calendar. https://fullcalendar.io/docs/contentHeight
        contentHeight: "auto",
        // Sets the height of the entire calendar, including header and footer. https://fullcalendar.io/docs/height
        height: "auto",
        // size of left panel https://fullcalendar.io/docs/resourceAreaWidth
        resourceAreaWidth: "200px",
        // header content of the resource column
        resourceAreaColumns: [{headerContent: ""}],
        // Determines the width of the area that contains the list of resources. https://fullcalendar.io/docs/nowIndicator
        nowIndicator: true,
        // Determines whether the events on the calendar can be modified. https://fullcalendar.io/docs/editable
        editable: true,
        // Determines whether the user can drag events between resources. https://fullcalendar.io/docs/eventResourceEditable
        eventResourceEditable: false,
        // Allow events’ start times to be editable through dragging. https://fullcalendar.io/docs/eventStartEditable
        eventStartEditable: true,
        // Apply class names to all calendar event, directly from their status. https://fullcalendar.io/docs/classname-input
        eventClassNames: function (arg: EventContentArg) {
          let classes: string[] = []
          classes.push(arg.event.extendedProps.status)
          return classes
        },
        eventMouseEnter: function(mouseEnterInfo) {
          mouseEnterInfo.el.classList.add("elevation-6")
          if(mouseEnterInfo.el.classList.contains('available')){
            mouseEnterInfo.el.classList.add("available-dark")
          } else if(mouseEnterInfo.el.classList.contains('unavailable')){
            mouseEnterInfo.el.classList.add("unavailable-dark")
          } else if(mouseEnterInfo.el.classList.contains('contract')){
            mouseEnterInfo.el.classList.add("contract-dark")
          }
        },
        eventMouseLeave: function(mouseLeaveInfo) {
          mouseLeaveInfo.el.classList.remove("elevation-6")
          if(mouseLeaveInfo.el.classList.contains('available')){
            mouseLeaveInfo.el.classList.remove("available-dark")
          } else if(mouseLeaveInfo.el.classList.contains('unavailable')){
            mouseLeaveInfo.el.classList.remove("unavailable-dark")
          } else if(mouseLeaveInfo.el.classList.contains('contract')){
            mouseLeaveInfo.el.classList.remove("contract-dark")
          }
        },
        // Allows a user to highlight multiple days or timeslots by clicking and dragging.  https://fullcalendar.io/docs/selectable
        selectable: true,
        // Whether to draw a “placeholder” event while the user is dragging. https://fullcalendar.io/docs/selectMirror
        selectMirror: true,
        // Determines whether the user is allowed to select periods of time that are occupied by events. https://fullcalendar.io/docs/selectOverlap
        selectOverlap: true,
        // Configure time zone,
        timeZone: "UTC",
        // Determines if events being dragged and resized are allowed to overlap each other. https://fullcalendar.io/docs/eventOverlap
        eventOverlap: false,
      } as CalendarOptions,
      // UI variables
      dialog: false, // true to show modal
      creationDialog: false, // true to show modal
      // String or empty to inform vue that the value is nullable. In no cases it will be null tho (error fix)
      title: "",
      status: "",
      isLoading: false as boolean,
      statusArray: ['available', 'unavailable', 'contract'],
      selectedEvent: {} as EventCalendar,
      process: "",
      deleting: false,
      comment: "",
      eventStatus: null as number|null,
      limit: 500,
      countingString: 0,
    }
  },
  computed: {
    locale() {
      return this.$vuetify.lang.current
    },
  },
  beforeMount() {
    // Start - configure fullcalendar options which required component instance
    this.calendarOptions.select = this.handleDateSelect
    this.calendarOptions.eventResize = this.handleEventResize
    this.calendarOptions.eventDrop = this.handleEventDrop
    this.calendarOptions.eventClick = this.handleEventClick
    // handlers and initialized in the 'created' method of the component
    this.calendarOptions.loading = this.handleLoading
    // fetch Resources and Events
    this.calendarOptions.events = this.getEvents
    this.calendarOptions.resources = this.getResources
    this.calendarOptions.resourceOrder = this.resourceOrder
    if (this.calendarOptions.views) {
      this.calendarOptions.views.resourceTimelineCustomWeek.slotLabelFormat = [
        // first header
        {
        day: "numeric",
        weekday: "long",
        meridiem: "short",
        },
        // second header
        this.formatIntervalLabel
      ]
    }
    // Explicitly sets the “today” date of the calendar. This is the day that is normally highlighted in yellow. https://fullcalendar.io/docs/now
    this.calendarOptions.now = this.getLocaleNow
    // End - configure fullcalendar options which required component instance
  },
  watch: {
    locale: {
      handler: function (val) {
        this.calendarOptions.locale = val
      },
    },
    resources: function () {
      (this.$refs.fullCalendar as InstanceType<typeof FullCalendar>)
        .getApi()
        .refetchResources()
    },
    eventStatus: {
      handler: function (val) {
        this.setStatus(val)
      },
    },
    comment: {
      handler: function (val) {
        // detect number of emoji into text area input
        let nbEmoji = [...val.matchAll(emojiPatternRegex)].length
        // get exact length of comment (read emoji as 12 length word)
        let totalBrutLengthNewEvent = encodeURIComponent(val).length
        // replace number of emoji block by 2 length count (for saving utf-8 php back)
        this.countingString = totalBrutLengthNewEvent - (nbEmoji*EMOJI) + (nbEmoji * 4)
      },
    },
    creationDialog: {
      handler: function (val) {
        if (!val) {
          this.eventStatus = null
          if (this.process === 'create') {
            this.comment = ''
          }
        } else {
          if (this.process === "edit") {
            this.startDatetime = {dateObject: (new Date(this.$data.selectedEvent.event._def.extendedProps.date_begin)), dateString: "", timeString: ""}
            this.endDatetime = {dateObject: new Date(this.$data.selectedEvent.event._def.extendedProps.date_end), dateString: "", timeString: ""}
          }
          if (this.process === "create") {
            let date1 = new Date(
                this.$data.selectedEvent.start.getUTCFullYear(),
                this.$data.selectedEvent.start.getUTCMonth(),
                this.$data.selectedEvent.start.getUTCDate(),
                this.$data.selectedEvent.start.getUTCHours(),
                this.$data.selectedEvent.start.getUTCMinutes(),
                this.$data.selectedEvent.start.getUTCSeconds(),
                this.$data.selectedEvent.start.getUTCMilliseconds(),
              )

              let date2 = new Date(
                this.$data.selectedEvent.end.getUTCFullYear(),
                this.$data.selectedEvent.end.getUTCMonth(),
                this.$data.selectedEvent.end.getUTCDate(),
                this.$data.selectedEvent.end.getUTCHours(),
                this.$data.selectedEvent.end.getUTCMinutes(),
                this.$data.selectedEvent.end.getUTCSeconds(),
                this.$data.selectedEvent.end.getUTCMilliseconds(),
              )

            this.startDatetime = {dateObject: date1, dateString: "", timeString: ""}
            this.endDatetime = {dateObject: date2, dateString: "", timeString: ""}
          }
        }
      },
    },
    selectedItems: {
      handler: function (val) {
        // rerender FullCalendar for updated checkboxes
        (this.$refs.fullCalendar as InstanceType<typeof FullCalendar>)
          .getApi()
          .render()
      }
    }
  },
  methods: {
    setStatus(value: number) {
      switch (value) {
        case 0:
            this.status = 'available'
          break
        case 1:
            this.status = 'unavailable'
          break
        case 2:
            this.status = 'contract'
          break
        default:
            this.status = ''
      }
    },
    startingProcess(){
      let availability: AvailabilityBody = {}
      availability.comment = ''
      if (this.status === 'contract') {
        availability.comment = this.comment
      }
      availability.status = this.status
      if (this.process == 'create') {
        this.saveAvailability(availability)
      }
      if(this.process === 'edit') {
        availability.date_begin = `${this.startDatetime.dateString} ${this.startDatetime.timeString}:00`
        availability.date_end = `${this.endDatetime.dateString} ${this.endDatetime.timeString}:00`
        if (availability.date_begin === " :00") {
          delete availability.date_begin
        }
        if (availability.date_end === " :00") {
          delete availability.date_end
        }

        this.updateAvailability(this.selectedEvent, availability)
      }
    },
    handleEventClick(info : EventClickArg){
      this.process ='edit'
      this.$data.selectedEvent = info
      this.showModal()
    },
    saveAvailability(availability: AvailabilityBody): void {
      availability.date_begin = this.startDatetime.dateString
        ? `${this.startDatetime.dateString} ${this.startDatetime.timeString}:00`
        : new Date(this.$data.selectedEvent.start).toISOString().replace(/T/, " ").replace(/.000Z/, "")

      availability.date_end = this.endDatetime.dateString
        ? `${this.endDatetime.dateString} ${this.endDatetime.timeString}:00`
        : new Date(this.$data.selectedEvent.end).toISOString().replace(/T/, " ").replace(/.000Z/, "")

      axios.post(`/v1/agenda/${this.$data.selectedEvent.resource._resource.id}/availabilities`,
        {
          "status": availability.status,
          "comment": availability.comment,
          "date_begin": availability.date_begin,
          "date_end": availability.date_end,
          "author": "space",
          "log": "chronology"
        }
      ).then(()=> {
        (this.$refs.fullCalendar as InstanceType<typeof FullCalendar>).getApi().refetchEvents()
        EventBus.$emit('snackbar', { message: this.$vuetify.lang.t('$vuetify.save_availability_success') }) // show snackbar
      }).catch((error: AxiosError) => {
        if (error.response?.status === 400) {
          (this.$refs.fullCalendar as InstanceType<typeof FullCalendar>).getApi().refetchEvents()
          EventBus.$emit('snackbar', { message: this.$vuetify.lang.t('$vuetify.save_availability_fail'), color: 'error' })
        } else {
          EventBus.$emit('snackbar', { axiosError: error })
        }
      }).finally(() => {
          this.resetData()
        }
      )
    },
    deleteEvent(): void {
      this.deleting = true
      axios.delete(`/v1/agenda/${this.selectedEvent.event._def.resourceIds?.[0]}/availabilities/${this.selectedEvent.event.id}`).then(()=> {
        (this.$refs.fullCalendar as InstanceType<typeof FullCalendar>).getApi().refetchEvents()
        EventBus.$emit('snackbar', { message: this.$vuetify.lang.t('$vuetify.delete_availability_success') }) // show snackbar
      }).catch((error: AxiosError) => {
        if (error.response?.status === 400) {
          (this.$refs.fullCalendar as InstanceType<typeof FullCalendar>).getApi().refetchEvents()
          EventBus.$emit('snackbar', { color: 'error', message: this.$vuetify.lang.t('$vuetify.delete_availability_fail') })
        } else {
          EventBus.$emit('snackbar', { axiosError: error })
        }
      }).finally(() => {
          this.resetData()
        }
      )
    },
    resetData(){
      this.process = ""
      this.creationDialog = false
      this.$data.selectedEvent = {}
      this.deleting = false
      this.comment = ""
      this.status = ""
      this.eventStatus = null
    },
    handleDateSelect(info: DateSelectArg) {
      this.process = "create"
      // show modal
      this.$data.selectedEvent = info
      this.showModal()
    },
    showModal() {
      //update input modal with selected event value
      if (this.$data.selectedEvent.event) {
        this.comment = this.$data.selectedEvent.event._def.extendedProps.comment ?
        this.$data.selectedEvent.event._def.extendedProps.comment : ''
        this.status = this.$data.selectedEvent.event._def.extendedProps.status ?
        this.$data.selectedEvent.event._def.extendedProps.status : ''
        this.eventStatus = this.setEventStatus(this.status)
      }
      this.creationDialog = true
    },
    setEventStatus(status: string) {
      //transform status to number to set input status in modal
        return +this.statusArray.indexOf(status)
      }
    ,
    handleEventResize(info: EventResizeDoneArg): void {
      let body = null
      if (info.oldEvent.startStr !== info.event.startStr) {
      body = {"date_begin": info.event.startStr.replace('T', ' ').replace('Z', '')}
      }
      if (info.oldEvent.endStr !== info.event.endStr) {
        body = {"date_end": info.event.endStr.replace('T', ' ').replace('Z', '')}
      }
      if (body !== null) {
        this.updateAvailability(info, body)
      }
    },
    // Drag an drop event
    handleEventDrop(info: EventDropArg): void {
      if (
        info.oldEvent.startStr !== info.event.startStr &&
        info.oldEvent.endStr !== info.event.endStr) {
          this.updateAvailability(
            info,
            {
              date_begin: info.event.startStr.replace('T', ' ').replace('Z', ''),
              date_end : info.event.endStr.replace('T', ' ').replace('Z', '')
            })
      }
    },
    updateAvailability(info: EventCalendar | EventDropArg | EventResizeDoneArg, body : AvailabilityBody) {
      axios.put(`/v1/agenda/${info.event._def.resourceIds?.[0]}/availabilities/${info.event.id}`,
          body
        ).then(()=> {
          (this.$refs.fullCalendar as InstanceType<typeof FullCalendar>).getApi().refetchEvents()
          EventBus.$emit('snackbar', { message: this.$vuetify.lang.t('$vuetify.update_availability_success') }) // show snackbar
        }).catch((error) => {
          if (error.response?.status === 400) {
            (this.$refs.fullCalendar as InstanceType<typeof FullCalendar>).getApi().refetchEvents()
            EventBus.$emit('snackbar', { message: this.$vuetify.lang.t('$vuetify.save_availability_fail'), color: 'error' })
          } else {
            EventBus.$emit('snackbar', { axiosError: error })
          }
          info.revert() // revert event on calendar
        }).finally(()=>{
          this.resetData()
        })
    },
    formatIntervalLabel(dateObject: VerboseFormattingArg): string {
      if (dateObject.date.hour == 6) {
        return "🌅 " + this.$vuetify.lang.t("$vuetify.morning")
      } else if (dateObject.date.hour == 14) {
        return "🌇 " + this.$vuetify.lang.t("$vuetify.evening")
      } else {
        return "🌘 " + this.$vuetify.lang.t("$vuetify.night")
      }
    },
    handleLoading(isLoading: boolean): void {
      this.isLoading = isLoading
    },
    handleAPIError(error: AxiosError): void {
      EventBus.$emit('snackbar',{axiosError: error}) // show snackbar
    },
    getResources(
      arg: any,
      successCallback: (resources: ResourceInput[]) => void,
      failureCallback: (errorObj: any) => void
    ): void {
      // successCallback is call with formatted ressources
      successCallback(this.resources as Array<ResourceInput>)
      // NOTE - Events seems to be fetched always before Resources
      // So we need to refetch them when resources are available
      this.$nextTick(function () {
        (this.$refs.fullCalendar as InstanceType<typeof FullCalendar>)
          .getApi()
          .refetchEvents()
      })
    },
    getEvents(
      fetchInfo: any,
      successCallback: (events: EventInput[]) => void,
      failureCallback: (error: any) => void
    ): void {
      // NOTE - FullCalendar always seems to call getEvents before getResources
      // prevent execution when there is no resources
      if ((this.resources as Array<ResourceInput>).length === 0) {
        failureCallback({ message: "No resources detected" })
      }
      // initialization
      let events: EventInput[] = []
      let calls: Promise<AxiosResponse>[] = []
      let recalls: Promise<AxiosResponse>[] = []
      // format start/end datetime
      let from = fetchInfo.startStr.replace("T", " ").replace("Z", "") // startStr example: 2021-07-01T00:00:00Z"
      let to = fetchInfo.endStr.replace("T", " ").replace("Z", "") // endStr example: 2021-07-01T00:00:00Z"
      // prepare all API calls
      let currentResources = this.resources as ResourceInput[] // NOTE - we must set resources to a new let to make typescript happy
      currentResources.forEach((resource: ResourceInput) => {
        calls.push(
          axios.get(`/v1/agenda/${resource.id}/availabilities`, {
            params: {
              page: 1,
              from: from,
              to: to,
              status: "available,unavailable,contract",
              worker_id: resource.id, // CRADE
            },
          })
        )
      })
      // perform all API calls & retrieve events
      Promise.all(calls)
        .then((results) => {
          results.forEach((result: AxiosResponse) => {
            let match = /page=(\d+)[^>]*>;\s*rel="last"/m.exec(
              result.headers.link
            )
            if (match && match[1] && parseInt(match[1]) > 1) {
              for (let i = 2; i <= parseInt(match[1]); i++) {
                recalls.push(
                  axios.get(`/v1/agenda/${result.config.params.worker_id}/availabilities`, {
                    params: {
                      page: i,
                      from: from,
                      to: to,
                      status: "available,unavailable,contract",
                      worker_id: result.config.params.worker_id, // CRADE
                    },
                  })
                )
              }
            }
            events = events.concat(this.formatAPIAvailabilities(result))
          })
        })
        .then(() => {
          Promise.all(recalls)
            .then((results) => {
              results.forEach((result: AxiosResponse) => {
                events = events.concat(this.formatAPIAvailabilities(result))
              })
            })
            .catch((error) => {
              this.handleAPIError(error)
              failureCallback({ message: "Error while retrieving events" })
            })
            .finally(() => {
              successCallback(events)
            })
        })
        .catch((error) => {
          this.handleAPIError(error)
          failureCallback({ message: "Error while retrieving events" })
        })
    },
    formatAPIAvailabilities(response: AxiosResponse): EventInput[] {
      return response.data.map((event: APIAvailability) => {
        return {
          id: String(event.id), // PK
          resourceId: String(response.config.params.worker_id), // resourceId is always a string
          title: "#" + event.id + " " + event.status, // NEED to set title
          start: event.date_begin.replace(" ", "T") + "Z", // transform "2021-06-25 09:23:00" into "2021-06-25T09:23:00Z"
          end: event.date_end.replace(" ", "T") + "Z", // transform "2021-06-25 09:23:00" into "2021-06-25T09:23:00Z"
          extendedProps: event, // store event data
        }
      })
    },
    getLocaleNow() {
      let date = new Date()
      return new Date(date.getTime() - date.getTimezoneOffset()*60000)
    }
  },
})
